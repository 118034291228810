<!-- About Section Start -->
<div id="rs-about" class="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 md-mb-30">
                <div class="rs-animation-shape">
                    <div class="images">
                        <img class="dance3 h-500" src="assets/images/stellion.png" alt="">
                    </div>
                    <div class="middle-image2">
                        <img class="dance3" src="assets/images/about/effect.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 pl-60 md-pl-15">
                <div class="contact-wrap">
                    <div class="sec-title mb-30">
                        <div class="sub-text style-bg">Quiénes somos</div>
                        <h2 class="title pb-38">
                            Stellion Tech
                        </h2>
                        <p class="margin-0 pb-15">
                            Somos una empresa que ofrece soluciones tecnológicas innovadoras, brindamos consultoría, capacitación, diseño, implementación y mantenimiento de tecnologías Cloud y 
                            Devops, creando una relación de confianza con los clientes, adaptando las principales tendencias tecnológicas al mercado local, con el fin de que las Startups y pymes 
                            puedan entregar valor de manera rápida y eficiente.
                            Nuestros chatbots inteligentes están al alcance de todos, siguiendo las tendencias actuales del mercado que implican la automatización de procesos y respuestas en lenguajes natural.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Section End -->
