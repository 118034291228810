<!-- Services Section Start -->
<div id="rs-services" class="rs-services main-home style1 pt-100 md-pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/4.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a>Automatización de atención y soporte a clientes</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Los chatbots pueden proporcionar atención al cliente las 24 horas del día, los 7 días de la semana, lo que puede ayudar a reducir los costos y mejorar la satisfacción del cliente.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/1.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a>Fácil integración y ahorro de costos</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Integra tus chatbots inteligentes con Whatsapp, mediante APIs o simplemente utiliza nuestras interfaces en tu sitio web.
                                Los chatbots pueden ayudar a reducir los costos al automatizar tareas que de otro modo serían realizadas por empleados humanos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/2.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a>Tecnología escalable y mejora de procesos</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                No te preocupes por el número de usuarios, nuestra infraestructura es complementamente escalable y adaptable.
                                Los chatbots recopilan datos sobre las interacciones con los clientes, lo que ayuda a las empresas a mejorar sus productos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-md-6 md-mb-30">
                <div class="services-item min-h-520">
                    <div class="services-icon">
                        <div class="image-part">
                            <img src="assets/images/services/5.png" alt="">
                        </div>
                    </div>
                    <div class="services-content">
                        <div class="services-text">
                            <h3 class="services-title"><a>No te preocupes por administración ni contratos de por vida</a></h3>
                        </div>
                        <div class="services-desc">
                            <p>
                                Nuestro modelo de negocio trabaja en base a suscripciones anuales por el uso del servicio, contáctanos ahora para mas información
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Section End -->
