<!-- Technology Section Start -->
<div id="rs-technologies" class="rs-technology gray-color pt-120 pb-120 md-pt-80 md-pb-80">
    <div class="container">
        <div class="sec-title2 text-center mb-45">
            <video width="640" height="360" controls>
              <source src="assets/videos/my_video.mp4" type="video/mp4">
              Tu navegador no admite el formato de video MP4.
            </video>
          </div>
        <div class="sec-title2 text-center mb-45">
            <span class="sub-text gold-color">tecnologías</span>
            <h2 class="title title2">
                Cuáles tecnologías usamos para darle valor a nuestros clientes
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/1.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/2.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/3.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/4.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/6.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/8.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/9.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/11.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/12.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/15.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/16.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/21.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                <div class="technology-item">
                    <a>
                        <div class="logo-img">
                            <img src="assets/images/technology/22.png" alt="">
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-lg-12 text-center">
            <div class="btn-part mt-30">
                <a class="readon started">Get Started</a>
            </div>
        </div>
    </div>
</div>
<!-- Technology Section End -->