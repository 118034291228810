<!-- Contact Section Start -->
<div id="rs-contact" class="rs-contact gray-color pt-120 pb-120 md-pt-80">
    <div class="container">
        <div class="sec-title2 text-center mb-45">
            <h2 class="title title2">
                Contáctanos
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-6 offset-md-3 md-mb-60">
                <div class="contact-box">
                    <div class="sec-title mb-45">
                        <span class="sub-text new-text white-color">Hablemos!</span>
                        <h2 class="title white-color">Contáctanos ahora para iniciar tu camino con la inteligencia artificial</h2>
                    </div>
                    <div class="address-box mb-25">
                        <div class="address-icon">
                            <i class="fa fa-home"></i>
                        </div>
                        <div class="address-text">
                            <span class="label">Email:</span>
                            <a>info@stelliontech.com</a>
                        </div>
                    </div>
                    <div class="address-box mb-25">
                        <div class="address-icon">
                            <i class="fa fa-phone"></i>
                        </div>
                        <div class="address-text">
                            <span class="label">Phone:</span>
                            <a href="https://wa.me/+573233427142">+57 (323) 3427142</a>
                        </div>
                    </div>
                    <div class="address-box">
                        <div class="address-icon">
                            <i class="fa fa-map-marker"></i>
                        </div>
                        <div class="address-text">
                            <span class="label">Address:</span>
                            <div class="desc">Medellín, Colombia</div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<!-- Contact Section Start -->