<!-- Footer Start -->
<footer id="rs-footer" class="rs-footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 footer-widget">
                    <div class="footer-logo mb-30">
                        <a href="index.html"><img src="assets/images/logo-dark.png" alt=""></a>
                    </div>
                      <div class="textwidget pb-30 desc"><p>Nuestro trabajo busca generar un conocimiento en las últimas tendencias tecnológicas y metodologías de trabajo en los equipos de nuestros clientes.</p>
                      </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                    <h3 class="widget-title">IT Services</h3>
                    <ul class="site-map">
                        <li><a href="software-development.html">Software Development</a></li>
                        <li><a href="web-development.html">Web Development</a></li>
                        <li><a href="case-studies-single.html">Analytic Solutions</a></li>
                        <li><a href="cloud-and-devops.html">Cloud and DevOps</a></li>
                        <li><a href="product-design.html">Project Design</a></li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 md-mb-30">
                    <h3 class="widget-title">Contact Info</h3>
                    <ul class="address-widget">
                        <li>
                            <i class="flaticon-location"></i>
                            <div class="desc">Medellín, Colombia</div>
                        </li>
                        <li>
                            <i class="flaticon-call"></i>
                            <div class="desc">
                               <a href="https://wa.me/+573233427142">+57 (323) 3427142</a>
                            </div>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <div class="desc">
                                <a href="mailto:info@stelliontech.com">info@stelliontech.com</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer End -->